.badge {
  border-radius: 100px;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 10px;
}

.badge-success {
  background-color: rgba(36, 161, 72, 0.1);
  color: $color-success;
}

.badge-danger {
  background-color: rgba(218, 30, 40, 0.1);
  color: $color-danger;
}

.badge-warning {
  background-color: #fff3cd;
  color: #856404;
}

.badge.premium {
  background: rgba(35, 201, 251, 0.1);
  border: 1px solid rgba(35, 201, 251, 0.1);
  color: #007fa5;
  position: relative;
  svg {
    position: relative;
    margin-right: 4px;
  }
}