.options-group {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  .option {
    background-color: $color-background-light;
    border: 1px solid $color-border-light;
    border-radius: 100px;
    padding: 2px 10px;
    font-size: 13px;
    white-space: nowrap;
    margin-right: 5px;
    transition: all 300ms;
    &:hover {
      background-color: $color-background;
      cursor: pointer;
    }
    &.active {
      background-color: rgba($color-primary, 0.1);
      border-color: rgba($color-primary, 0.4);
      color: $color-primary;
    }
  }
}
