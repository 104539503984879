@import '../variables.scss';

/** 
 * Table styles
 */

@mixin width {
  width: auto;
}

thead {
  th {
    background: #f9f9fb !important; // #f9f9fb
    border-bottom: 0;
    color: rgba(7, 11, 31, 0.5);
    font-weight: 500;
    font-size: 13px !important;
    padding-bottom: 10px !important;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    transition: background 300ms ease;
    svg {
      fill: $color-primary-grey;
      opacity: 0.4;
      margin-left: 8px;
      position: relative;
      top: -1px;
      transition: fill 300ms, opacity 400ms;
      &.active {
        fill: $color-body;
        opacity: 0.9;
      }
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }

    &.active,
    &.sortable {
      cursor: pointer;
      &:hover {
        background: rgba(244, 244, 247, 0.8) !important; // #f4f4f7
      }
    }
    &.active {
      color: $color-body !important;
    }
  }
  tr.main th {
    color: $color-body;
  }
}

tbody tr td {
  padding-top: 16px;
  padding-bottom: 11px;
}

thead th,
tbody td {
  border-left: 0;
  border-color: #f1f2f3 !important;
  font-size: 15px;
  &.hidden {
    color: transparent !important;
  }
  &:last-child {
    border-right: 0;
  }
}

.table-bordered td,
.table-bordered th {
  border: 1px solid;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f9f9fb;
}

.table-hover tbody tr:hover {
  background-color: inherit;
  td {
    background-color: inherit;
  }
}

// fit tables
.table-fit {
  @include width;
  table-layout: auto;
  th {
    @include width;
  }
  td {
    @include width;
  }
  td,
  th {
    font-size: 15px;
    white-space: nowrap;
    width: 1%;
  }
}

// table color codes
td {
  &.good {
    color: #fff;
    background: rgb(26, 175, 84) !important;
  }
  &.low {
    color: $color-body;
    background: rgb(254, 217, 49) !important;
  }
  &.very-low {
    color: #fff;
    background: rgb(252, 102, 33) !important;
  }
  &.critical {
    color: #fff;
    background: rgb(252, 13, 27) !important;
  }
}

// sticky cells
th.sticky,
td.sticky {
  background: #fff;
  position: sticky;
  left: 0px;
}
th.sticky {
  background: #f9f9fb;
}

th.sticky span,
td.sticky > *,
td.truncate span {
  display: block;
  width: 320px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
