/** 
 * Card styles
 */

.card {
  background: #fff;
  border: 1px solid #f1f2f3;
  border-radius: 0;
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-width: 0;
  &.contained {
    padding: 16px 16px;
  }
  &.bordered {
    border: 1px solid $color-border-light;
  }
  &.bordered-bottom {
    border-bottom: 1px solid $color-border-light;
  }
}

.card-header {
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #f1f2f3;
  border-radius: 8px 8px 0 0 !important;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  &.odd {
    background: $color-background-light;
    border-radius: 0 !important;
    padding-top: 12px;
  }
}

.card-title {
  font-size: 15px;
  font-weight: 500;
  margin: 2px 0 0;
}

.card-body {
  padding: 16px;
  position: relative;
  &::after {
    content: '';
    background: rgba(255, 255, 255, 1);
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    visibility: hidden;
  }
  &.disabled::after {
    opacity: 0.7;
    visibility: visible;
  }
}

.card-section {
  border-bottom: 1px solid #f1f2f3;
  padding: 16px;
}

.card-footer {
  background: #fff;
  border-top: 1px solid #f6f6f6;
  border-radius: 0 0 8px 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
