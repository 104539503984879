.modal-backdrop {
  z-index: 100;
}

.modal-dialog {
  display: flex;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 100%;
  .modal-content {
    border: 0;
    border-radius: 15px;
    z-index: 101;
  }
  .modal-header {
    align-items: center;
    background: #fff;
    border-bottom: 1px solid $color-border-light;
    border-radius: 15px 15px 0 0;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    &.odd {
      background: $color-background-light;
      border-radius: 0 !important;
      padding-top: 12px;
    }
    .left,
    .right {
      flex-basis: 20%;
    }
    .center {
      flex-basis: 60%;
      display: flex;
      justify-content: center;
    }
    .right {
      display: flex;
      justify-content: flex-end;
    }
  }
  .modal-title {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
  .modal-body {
    overflow-y: scroll;
    &.odd {
      background: $color-background-light;
    }
  }
}

.modal.full {
  .modal-dialog {
    justify-content: flex-end;
    position: fixed;
    max-width: 100%;
    z-index: 101;
    .modal-content {
      border-radius: 0;
      height: 100%;
    }
    .modal-header {
      border-radius: 0;
    }
  }
}

.modal.mini {
  .modal-content {
    max-width: 300px;
  }
}

.modal.fade .modal-dialog {
  transition: transform 400ms ease-in-out, opacity 400ms;
  transform: translate(0, 200%);
}
.modal.full.fade .modal-dialog {
  transform: translate(0, 200%);
}

.modal.show .modal-dialog,
.modal.full.show .modal-dialog {
  transform: translate(0, 0);
}
