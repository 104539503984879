@import '../../../assets/sass/variables.scss';

.bottomNav {
  background-color: #fff;
  border-top: 1px solid $color-border-light;
  position: fixed;
  bottom: -2px;
  padding: 5px 16px 0;
  width: 100%;
  z-index: 10;
  height: 70px;
  vertical-align: middle;
  .links {
    flex-direction: row !important;
    justify-content: space-between;
    flex-wrap: nowrap;
    :global .nav-link {
      border-radius: 4px;
      color: $color-gray-light;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      align-items: center;
      padding: 6px 15px !important;
      position: relative;
      // margin: 0 10px;
      margin: 0;
      opacity: 0.8;
      width: 50px;
      transition: all 400ms ease-in-out;
      &.active {
        color: $color-primary;
        opacity: 1;
      }
      svg {
        width: 22px;
        height: 22px;
        stroke-width: 1.6px;
      }
      .badge {
        background-color: $color-danger;
        border-radius: 100px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        width: 12px;
        height: 16px;
        line-height: 0;
        position: absolute;
        top: 5px;
        right: -7px;
        transition: all 400ms;
      }
    }
  }
}

.pad {
  height: 70px;
}
