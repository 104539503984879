@import '../../../../core/assets/sass/variables.scss';

.wrapper {
  background: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -6px 20px 0 rgba(0, 0, 0, 0.07);
  padding: 20px 16px 30px;
  transform: translateY(200%);
  transition: transform 300ms cubic-bezier(0.75, -0.25, 0.25, 0.75);
  z-index: 100;
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: 50px;
    height: 6px;
    top: 10px;
    left: calc(50% - 50px / 2);
    bottom: 163px;
    background: #e7e8ef;
    border-radius: 100px;
  }
  &.open {
    transform: translateY(0);
  }

  h3.buyer-name {
    font-size: 17px;
    margin-bottom: 2px;
  }
}
