/**
 * Fonts, base styles and layout stuff
 */

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(../fonts/Inter-Regular.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(../fonts/Inter-Medium.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(../fonts/Inter-SemiBold.woff2);
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(../fonts/Inter-Bold.woff2);
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  background-color: $color-background-light;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: $color-body;
  font-family: $system-font;
  font-size: 15px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  height: 0px;
  background: transparent; /* Optional: just make scrollbar invisible */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-secondary;
  font-family: $system-font;
  font-weight: 600;
  line-height: 1.2;
}

a {
  color: $color-link;
  &:hover {
    text-decoration: none;
  }
}

hr {
  border-color: #f1f2f3;
}

small,
.small {
  font-size: 95%;
}

// layout
#app {
  background-color: $color-background-light;
  display: initial;
  width: 100%;
  justify-content: space-between;
}

.container {
  max-width: 100% !important;
}

.page-wrapper {
  width: 100%;
  margin: 0;
  transition: all 0.4s;
}

.page-container {
  margin: 30px 0;
}
