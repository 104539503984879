@import '../../../../core/assets/sass/variables.scss';

.form {
  :global .form-group {
    flex-wrap: nowrap;
    align-items: center;
  }
  :global .form-control {
    border: 0;
    justify-self: end;
    width: 100%;
    padding: 0;
    &:focus {
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  :global .input-group-prepend {
    margin-right: 10px;
  }
  :global .input-group-append {
    margin-left: 10px;
  }
  :global .input-group-text {
    border: 1px solid $color-border;
    border-radius: 6px !important;
    align-self: center;
  }
}
