/** 
 * Form and button styles
 */

label {
  font-size: 15px;
  margin-bottom: 0;
}

.btn {
  font-size: 15px;
  font-weight: 500;
  height: 42px;
  border-radius: 8px;
  padding: 8px 13px;
  line-height: 0;
  position: relative;
  transition: all 100ms ease-in-out;
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 14px;
  padding: 5px 10px;
  height: 34px;
  border-radius: 6px;
}

.btn-outline-light {
  color: $color-body !important;
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid $color-border-light;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid $color-border;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    color: $color-body;
  }
}

.btn.icon {
  width: 42px;
  height: 42px;
  padding: 0;
  line-height: 0;
  &.btn-sm {
    width: 34px;
    height: 34px;
  }
  &.btn-lg {
    width: 56px;
    height: 56px;
  }
  &.rounded {
    border-radius: 100px !important;
  }
  &.floating {
    position: fixed;
    bottom: 80px;
    right: 24px;
    z-index: 9;
  }
  svg {
    margin: 0;
    position: relative;
    top: 0;
  }
}

.form-text {
  margin-top: 0;
}

.invalid-feedback {
  display: block;
  font-size: 90%;
}

.form-control {
  border-color: $color-border;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  -webkit-appearance: none;
  &:disabled,
  &[readonly] {
    background-color: $color-background-light;
    opacity: 1;
  }
  &.borderless {
    border: 0;
    padding: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.form-control-sm {
  border-radius: 6px;
  font-size: 14px;
  height: 34px;
  // line-height: 0;
  padding: 5px 10px 6px;
}

.form-control-sm.date {
  width: 100px;
}

.form-control-lg {
  height: 50px;
  font-size: 18px;
}

select {
  &.form-control {
    appearance: none;
    -webkit-appearance: none;
    background-image: url(../../images/icons/caret.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center;
    background-size: 12px;
    padding-right: 30px !important;
  }
  &.form-control-sm {
    display: inherit;
    // padding-bottom: 0;
  }
}

.search-bar {
  overflow: hidden;
  position: relative;
  &.sticky {
    position: sticky;
    top: 60px;
    z-index: 10;
  }
  .form-control {
    background-color: #f5f5f8;
    background-image: url(../../images/icons/search.svg);
    background-repeat: no-repeat;
    background-position: 16px center;
    background-size: 18px;
    border-width: 0;
    border-radius: 0;
    font-size: 14px;
    padding-left: 2.5rem !important;
    padding-bottom: 0.35rem;
    height: 42px;
    width: 100%;
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }
  &__close {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    transition: transform 400ms;
    &.shown {
      transform: translateX(0);
    }
    &.hidden {
      transform: translateX(200%);
    }
  }
}

.input-group {
  .react-datepicker-wrapper:first-child .form-control {
    border-radius: 6px 0 0 6px;
  }
  .react-datepicker-wrapper:last-child .form-control {
    border-left: 0;
    border-radius: 0 6px 6px 0;
  }
}

.input-group-text {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0.45rem;
  border-color: $color-border;
  background: $color-background-light;
}

.input-group-append,
.input-group-prepend {
  z-index: 0;
}
