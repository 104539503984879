.w-auto {
  width: auto !important;
}

.cursor-pointer {
  cursor: pointer;
}

.action-link {
  font-size: 85%;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-primary;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 300ms;
  &.muted {
    color: #262626;
  }
  &:hover {
    color: $color-primary;
  }
}

.text-small-title {
  color: $color-gray;
  display: block;
  font-size: 85%;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 2px;
}

.text-currency {
  font-family: $system-font;
}

.text-muted {
  color: $color-gray !important;
}

.text-truncated {
  display: block;
  width: 260px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.large {
  font-size: 16px !important;
}
.largex2 {
  font-size: 18px !important;
}

.font-weight-medium {
  font-weight: 500;
}

.vertical-divider {
  border-right: 1px solid $color-border-light;
  height: 26px;
}

.spinner {
  background-image: url(../../images/icons/icon-spinner.svg);
  background-repeat: no-repeat;
  background-size: 36px;
  width: 36px;
  height: 36px;
  display: block;
  margin-bottom: 0;
  background-position: center;
  &.small {
    width: 24px;
    height: 24px;
    background-size: 24px;
  }
  &.button {
    width: 24px;
    height: 24px;
    background-size: 24px;
    position: relative;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
  &.primary {
    background-image: url(../../images/icons/icon-spinner-primary.svg);
  }
  &.white {
    background-image: url(../../images/icons/icon-spinner-white.svg);
  }
}

@keyframes shine {
  100% {
    transform: translateX(100%);
  }
}
