$system-font: 'Inter', system, -apple-system, system-ui, BlinkMacSystemFont,
  Helvetica Neue, Helvetica, Lucida Grande, sans-serif !important;

$brand-font: 'Inter', system-ui, 'Helvetica';

$color-body: #262626;
$color-link: #000000;
$color-primary: #3b54ec;
$color-accent: #23cbfd;
$color-secondary: #171c34;
$color-secondary-dark: #0c1326;
$color-success: #24a148;
$color-danger: #da1e28;

$color-primary-grey: #9da5b0;
$color-secondary-grey: #c4cad0;
$color-border-grey: #e7e8ef;

$color-background: #F5F5F8;
$color-background-light: #fafafd;
$color-border-light: #f1f2f3;
$color-border: #e7e8ef;
$color-gray-light: #9da5b0;
$color-gray: #808a97;
