.dropdown-toggle {
  &.btn-link {
    color: $color-gray;
    padding-left: 0;
    padding-right: 0;
  }
  svg {
    margin: 0 4px 0 0;
  }
  &::after {
    display: none;
  }
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

.dropdown-menu {
  border: 1px solid $color-border-light;
  border-radius: 6px;
  font-size: 15px;
  .dropdown-item {
    padding: 10px 15px;
    svg {
      color: $color-gray;
      margin-right: 8px;
    }
  }
}
