.nav-pills {
  &.card-header-pills {
    overflow: scroll;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin: 0;
  }

  .nav-link {
    color: #0c1326;
    display: block;
    font-weight: 500;
    padding: 0.4rem 1rem;
    text-align: center;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .nav-link.active,
  .show > .nav-link {
    color: #3b54ec;
    background-color: rgba(59, 84, 236, 0.1);
  }

  .nav-link.active,
  .show > .nav-link {
    color: #3b54ec;
    background-color: rgba(59, 84, 236, 0.1);
  }
}

.nav-tabs {
  border: 0;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  .nav-link {
    border: 0;
    border-bottom: 1px solid $color-border-light;
    color: $color-gray;
    font-weight: 500;
    padding: 0 0 8px;
    text-align: center;
    transition: all 300ms ease-in-out;
    &:after {
      content: ' ';
      position: relative;
      display: block;
      width: 40%;
      min-width: 30px;
      height: 4px;
      border-radius: 10px 10px 0 0;
      background: transparent;
      margin: 0 auto;
      bottom: -8px;
    }
    &:not(:last-child) {
      margin-right: 15px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &.active {
      border-color: $color-border-light;
      color: $color-primary;
      opacity: 1;
      &:after {
        background: $color-primary;
      }
    }
  }
}

.nav.card-header-pills.nav-tabs,
.nav.card-header-pills.nav-pills {
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
}
