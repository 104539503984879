@import '../../../../core/assets//sass/variables.scss';

.distributor {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  margin-top: -2px;
  top: 42px;
  border-top: 1px solid $color-border-light;
  padding: 8px 16px !important;
  z-index: 9;
}

.item {
  margin: 0 16px;
  padding: 10px 0;
  border-bottom: 1px solid $color-border-light;
  &.last {
    border: 0;
  }
  .subtotal-label {
    display: block;
    width: 60% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .subtotal-label,
  .subtotal-amount {
    font-weight: 500;
  }
}
